export const url_list = [

    "https://www.dropbox.com/scl/fi/3y0rl8tub0e78fbjxjzj3/IMG_6016.jpg?rlkey=qp04cyyms3vlvmeshai8my7uo&st=b2q4ndbe&raw=1",
    "https://www.dropbox.com/scl/fi/p4b1f0pl6tg2smwddklzx/IMG_6297.jpg?rlkey=4f0667jt7bvsbqhgpdjrbki2t&st=zkjmys0i&raw=1",
    "https://www.dropbox.com/scl/fi/rmk1g2klnp0mh3pf2t76j/1.jpg?rlkey=briqrqnkcqikf0gsliv6ks34f&raw=1",
    "https://www.dropbox.com/scl/fi/ig5ldj8n84r95ny3xqxe5/2.jpg?rlkey=m9d1jq5my4u470fj073uy7i9h&raw=1",
    "https://www.dropbox.com/scl/fi/bz9ljyvrho7rvd4qc5vwj/3.jpg?rlkey=ti0ye0424z6a0dzidchckta5j&raw=1",
    "https://www.dropbox.com/scl/fi/ztlszu76erjvq13s4sfx7/4.jpg?rlkey=rxea4qou4k6eafiysjmp8ewm1&raw=1",
    "https://www.dropbox.com/scl/fi/iagso84v6zdwvlrga8g3z/5.jpg?rlkey=vvsw6einvl8znwb905izzt0dz&raw=1",
    "https://www.dropbox.com/scl/fi/s9qbsn6pdhnpaidrmml9b/6.jpg?rlkey=g79f6a9hd7rulpccby4btrkgy&raw=1",
    "https://www.dropbox.com/scl/fi/oyk6gj6daq6owmkvss6en/7.jpg?rlkey=ngyi3qkr8qklb2o7d1jssohnm&raw=1",
    "https://www.dropbox.com/scl/fi/f6v3a6vnkcp66wm8iwszp/8.jpg?rlkey=ajyuzo6oz9tf4c34lv4n7l6yr&raw=1",
    "https://www.dropbox.com/scl/fi/3xvwk44kmhew27m4ijep9/9.jpg?rlkey=zf2q3p3uwrv5c4zfb8yxl2tyz&raw=1",
    "https://www.dropbox.com/scl/fi/7ffa2hfz21p75l09qkaaf/10.jpg?rlkey=1wv6kemf0ubq4hkxc0vmh2ro1&raw=1",
    "https://www.dropbox.com/scl/fi/81h0vzfj3g0wl36t0sl80/11.jpg?rlkey=fm5iecvrwaummazxdsqck6kcj&raw=1",
    "https://www.dropbox.com/scl/fi/fbs1yj2b20ss39mqrpot0/12.jpg?rlkey=jjm6w6ufb1clnl8y68sra4n5r&raw=1",
    "https://www.dropbox.com/scl/fi/gu9jyo42rwb2ex0crn7e6/18.jpg?rlkey=wodmlnevhulgi5eltr4o2dx0f&raw=1",
    "https://www.dropbox.com/scl/fi/o6kyesk0ce7rfpatyus7u/19.jpg?rlkey=o7p0qfjn7ss9kh15c2xbne7nv&raw=1",
    "https://www.dropbox.com/scl/fi/n1ixu91ekrv3ea29vv2cd/20.jpg?rlkey=53syxsghvzoopra6s9ljosutt&raw=1",
    "https://www.dropbox.com/scl/fi/f2goh0x4u48x4ooud573m/IMG_6143.jpg?rlkey=nxh878f0n2w4m9i3c8871i6xo&e=1&raw=1"
    


    // "https://drive.google.com/uc?id=1Sq38vaAtAjzFEoAnrjm4-JFdXmqkOy_W",
    // "https://drive.google.com/uc?id=1q3ZhfUjrij6UaXYXucnsr2SDGt8x16lA",
    // "https://drive.google.com/uc?id=1XCY0JqCX8yhcdqqrEHi_SmVINou2xGDy",
    // "https://drive.google.com/uc?id=1cESG73t-PDcQC-7Dt9mohTdBOuqxDiQL",
    // "https://drive.google.com/uc?id=1CsHY1eor0iF4e3AcYyoV6muQcbd_SlQO",
    // "https://drive.google.com/uc?id=1S0NskWO9rgI3UVS4Jf88LorWYS367yUD",
    // "https://drive.google.com/uc?id=1ue0d2np_2NRXDU0QnnuqknSSKvf2U398",
    // "https://drive.google.com/uc?id=1syNELu-WNltcyzWe1r6P4qCCD-v1BF59",
    // "https://drive.google.com/uc?id=1w3fEr41rHl2zejng-Q-6yTSNUGPz2P5L",
    // "https://drive.google.com/uc?id=18GUiyqhgYR2_hOabIH_TbGNepBEBqEJS",
    // "https://drive.google.com/uc?id=1DUNXKXr57pOHAPGxutMhNE_BYz5XmXdv",
    // "https://drive.google.com/uc?id=1B43NI4Xd7vU6e5AplQVtfVKvKoiUwMfa",
    // "https://drive.google.com/uc?id=1CePGP1pTO599hdiCuDnzajN_QjSHslPu",
    // "https://drive.google.com/uc?id=1dC-xlm8w2CpoI6a1gKckcMIdzo_cbpKV",
    // "https://drive.google.com/uc?id=1verImaT56SN5QrTnf9wGwUfu2LCCW1iA",
    // "https://drive.google.com/uc?id=16zngWPYCJpbP00itT9MbYLE3-i2QocZH",
    // "https://drive.google.com/uc?id=141GN3tTrjOWynItKdbLo3Epfqg3EQC02",
    // "https://drive.google.com/uc?id=1qiOg8R9sA0yP1kQm66EnVM6hCVGc_l3_",
    // "https://drive.google.com/uc?id=1Ic2FV1oIDEsypExaH2iHdMxMoGPGBagc",
    // "https://drive.google.com/uc?id=1Wc9yywe5V4-Mzu-w01tAmg_zKv8Lm2cX",
    // "https://drive.google.com/uc?id=1loxOznayoZQubBBiVhdMbSCFPXkkXOd4",
];
