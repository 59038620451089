import React from 'react'
import NavBarLine from "../components/nav-bar/NavBarLine";
import Footer from "../components/footer/Footer";
import PrivacyPolicyData from '../components/privacypolicyData/PrivacyPolicyData'
import "../components/privacypolicyData/style.css"

const PrivactPolicy = () => {
  return (
    <div className='custom_privacyPolict'>
      <NavBarLine/>
      <PrivacyPolicyData/>
      <Footer />
    </div>
  )
}

export default PrivactPolicy
